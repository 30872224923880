<template>
  <div>
    <vx-card title="Documents clients" class="mb-4">
      <div slot="no-body">
        <div class="p-8">
          <div>
            <vs-tabs>
              <vs-tab label="Atlantique Bouclier Quiétude  ">
                <div>
                  <aab-vie-doc-client
                    productName="admin_Setting_DocClient_atlantiqueBouclierQuiétude"
                  >
                  </aab-vie-doc-client>
                </div>
              </vs-tab>
              <vs-tab label="Atlantique Prévoyance Famille">
                <div>
                  <aab-vie-doc-client
                    productName="admin_Setting_DocClient_atlantiquePrévoyanceFamille"
                  >
                  </aab-vie-doc-client>
                </div>
              </vs-tab>
              <vs-tab label="Atlantique Horizon Retraite">
                <div>
                  <aab-vie-doc-client
                    productName="admin_Setting_DocClient_atlantiqueHorizonRetraite"
                  >
                  </aab-vie-doc-client>
                </div>
              </vs-tab>
              <vs-tab label="Atlantique Avenir Enfant">
                <div>
                  <aab-vie-doc-client
                    productName="admin_Setting_DocClient_atlantiqueAvenirEnfant"
                  >
                  </aab-vie-doc-client>
                </div>
              </vs-tab>
              <vs-tab label="Atlantique Assistance Funérailles">
                <div>
                  <aab-vie-doc-client
                    productName="admin_Setting_DocClient_atlantiqueAssistanceFunérailles"
                  >
                  </aab-vie-doc-client>
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import global from "@/config/global";
import { mixin } from "@/mixins/mixin";
import { mapActions, mapGetters } from "vuex";
import role from "@/config/role";

export default {
  data() {
    return {
      productId: "",
      productName: "",
      isOpen: false,
    };
  },

  created() {},
  mounted() {
    if (
      this.user.type != "super_administrator_sys" &&
      !this.user.person.userAcces.chk_customer_docs
    ) {
      if (!this.checkUserRole("customer_document").all)
        this.$router.push({ name: "Home" });
    }
    this.limit = this.rowsTable[1];
  },
  computed: {
    ...mapGetters("general", ["allProduct"]),
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
  },
};
</script>
